import React, { useCallback, useMemo, useState } from 'react';
import Input from '../common/Input';
import Button from '../common/Button';
import './ChangePassword.scss';
import { LOGIN_ADMIN_COOKIE, LOGIN_COOKIE, LOGIN_EMPLOYEE_COOKIE } from '../../constants';
import { getCookie, parseJwt, setCookie } from '../../utils';
import Toast from '../common/Toast';
import { postService } from '../../utils/service';
import Header from '../Header';

export interface ChangePasswordProps {
  isAdmin?: boolean;
  isEmployee?: boolean;
}

const ChangePassword = ({ isAdmin, isEmployee }: ChangePasswordProps) => {
  const [values, setValues] = useState<{
    password: string;
    rePassword: string;
  }>({
    password: '',
    rePassword: '',
  })
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const urlChangePassword = useMemo(() => {
    return isEmployee ? 'employee-change-password' : 'change-password';
  }, [isEmployee]);

  const cookieName = useMemo(() => {
    return isAdmin ? LOGIN_ADMIN_COOKIE : isEmployee ? LOGIN_EMPLOYEE_COOKIE : LOGIN_COOKIE;
  }, [isAdmin, isEmployee])

  const userName = useMemo(() => {
    return parseJwt(getCookie(cookieName)).userName;
  }, [cookieName])

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;

    setValues((prevValue) => ({
      ...prevValue,
      [name]: value
    }))
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const { data, error } = await postService(urlChangePassword, {
      ...values,
      userName,
    }) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    if (!isAdmin) {
      setCookie(cookieName, data.data, 2)
    }
    setHasError(false);
    setMsg('Đổi mật khẩu thành công.')
  }, [urlChangePassword, values, isAdmin, cookieName, userName]);

  return (
    <div className="change-password-component">
      <Header isAdmin={isAdmin} isEmployee={isEmployee} />
      <form className="change-password-component__form" onSubmit={handleSubmit}>
        <Input
          type="password"
          name="password"
          label="Mật khẩu"
          value={values.password}
          handleChange={handleChange}
        />
        <Input
          type="password"
          name="rePassword"
          label="Nhập lại mật khẩu"
          value={values.rePassword}
          handleChange={handleChange}
        />
        <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>Đổi mật khẩu</Button>
      </form>
      <Toast
        msg={msg}
        icon={hasError ? "fa-warning" : "fa-check-circle"}
        onClosed={() => setMsg('')}
      />
    </div>
  );
};

export default ChangePassword;
