import { Option } from '../components/common/Select'

export const LOGIN_COOKIE = 'manage_app';
export const LOGIN_EMPLOYEE_COOKIE = 'manage_employee';
export const LOGIN_ADMIN_COOKIE = 'manage_admin';

export const Pages = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  CHANGE_PASSWORD: '/change-password',
  NOT_FOUND: '/*'
}

export const EmployeePages = {
  HOME: '/employee',
  LOGIN: '/employee/login',
  REGISTER: '/employee/register',
  REPORT: '/employee/report',
  CHANGE_PASSWORD: '/employee/change-password',
}

export const AdminPages = {
  HOME: '/admin',
  LOGIN: '/admin/login',
  REGISTER: '/admin/register',
  REPORT: '/admin/report',
  CHANGE_PASSWORD: '/admin/change-password',
  MANAGE_ACCOUNTS: '/admin/manage-accounts',
}

export const StatusOptionsList = [
  {
    text: 'Chưa xử lý',
    value: 'chua-xu-ly',
    backgroundColor: 'rgba(255, 99, 132, 0.8)'
  },
  {
    text: 'Đang xử lý',
    value: 'dang-xu-ly',
    backgroundColor: 'rgba(255, 206, 86, 0.8)',
  },
  {
    text: 'Đã xử lý',
    value: 'da-xu-ly',
    backgroundColor: 'rgba(75, 192, 192, 0.8)',
  }
]

export const TargetOptionsList = [
  {
    text: 'Người bệnh',
    value: 'nguoi-benh',
    backgroundColor: 'rgba(255, 99, 132, 0.8)'
  },
  {
    text: 'Người nhà/ khách đến thăm',
    value: 'nguoi-nha',
    backgroundColor: 'rgba(54, 162, 235, 0.8)'
  },
  {
    text: 'Nhân viên y tế',
    value: 'nhan-vien',
    backgroundColor: 'rgba(255, 206, 86, 0.8)',
  },
  {
    text: 'Trang thiết bị / cơ sở hạ tầng',
    value: 'trang-thiet-bi',
    backgroundColor: 'rgba(75, 192, 192, 0.8)',
  }
]

export const PlaceOptionsList = [
  {
    text: 'Phòng Hành chính - Kế toán',
    value: 'phong-hanh-chinh'
  },
  {
    text: 'Phòng Kế hoạch - Nghiệp vụ',
    value: 'phong-ke-hoach'
  },
  {
    text: 'Phòng Điều dưỡng',
    value: 'phong-dieu-duong'
  },
  {
    text: 'Khoa Dược - TTB - Vật tư y tế',
    value: 'khoa-duoc'
  },
  {
    text: 'Khoa Xét nghiệm và CĐHA',
    value: 'khoa-xet-nghiem'
  },
  {
    text: 'Khoa Khám bệnh - Liên chuyên khoa',
    value: 'khoa-kham-benh'
  },
  {
    text: 'Khoa Hồi sức cấp cứu',
    value: 'khoa-hoi-suc'
  },
  {
    text: 'Khoa Ngoại tổng hợp',
    value: 'khoa-ngoai-tong-hop'
  },
  {
    text: 'Khoa Nội - Nhi - Nhiễm',
    value: 'khoa-noi-nhi'
  },
  {
    text: 'Khoa Chăm sóc sức khỏe sinh sản',
    value: 'khoa-cham-soc-suc-khoe'
  },
  {
    text: 'Khoa Y học cổ truyền và phục hồi chức năng',
    value: 'khoa-y-hoc-co-truyen'
  },
  {
    text: 'Khoa Kiểm soát bệnh tật và HIV/AIDS',
    value: 'khoa-kiem-soat'
  },
  {
    text: 'Khoa Y tế công cộng, Dinh dưỡng và ATTP',
    value: 'khoa-y-te-cong-cong'
  },
  {
    text: 'Khoa Dân số và phát triển',
    value: 'khoa-dan-so'
  }
]

export const TypeOptionsList = [
  {
    text: 'Chưa xảy ra',
    value: 'chua-xay-ra'
  },
  {
    text: 'Đang xảy ra',
    value: 'dang-xay-ra'
  },
  {
    text: 'Đã xảy ra',
    value: 'da-xay-ra'
  }
]

export const EvaluateOptionsList = [
  {
    text: 'Nhẹ',
    value: 'nhe'
  },
  {
    text: 'Bình thường',
    value: 'binh-thuong'
  },
  {
    text: 'Nặng',
    value: 'nang'
  }
]

export const IncidentGroupOptions = [
  {
    text: 'Thực hiện quy trình kỹ thuật, thủ thuật chuyên môn',
    value: 'thuc-hien-quy-trinh-ky-thuat'
  },
  {
    text: 'Nhiễm khuẩn bệnh viện',
    value: 'nhiem-khuan-benh-vien'
  },
  {
    text: 'Thuốc và dịch truyền',
    value: 'thuoc-va-dich-truyen'
  },
  {
    text: 'Máu và các chế phẩm máu',
    value: 'mau-va-cac-che-pham-mau'
  },
  {
    text: 'Thiết bị y tế',
    value: 'thiet-bi-y-te'
  },
  {
    text: 'Hành vi',
    value: 'hanh-vi'
  },
  {
    text: 'Tai nạn đối với người bệnh',
    value: 'tai-nan-doi-voi-nguoi-benh'
  },
  {
    text: 'Hạ tầng cơ sở',
    value: 'ha-tang-co-so'
  },
  {
    text: 'Quản lý nguồn lực, tổ chức',
    value: 'quan-ly-nguon-luc-to-chuc'
  },
  {
    text: 'Hồ sơ, tài liệu, thủ tục hành chính',
    value: 'ho-so-tai-lieu-thu-tuc-hanh-chinh'
  },
  {
    text: 'Khác',
    value: 'khac'
  }
]

export const IncidentReasonOptions = [
  {
    text: 'Nhân viên',
    value: 'nhan-vien'
  },
  {
    text: 'Người bệnh',
    value: 'nguoi-benh'
  },
  {
    text: 'Môi trường làm việc',
    value: 'moi-truong-lam-viec'
  },
  {
    text: 'Tổ chức/ dịch vụ',
    value: 'to-chuc-dich-vu'
  },
  {
    text: 'Yếu tố bên ngoài',
    value: 'yeu-to-ben-ngoai'
  },
  {
    text: 'Khác',
    value: 'khac'
  }
]

export const DiscussOption = [
  {
    text: 'Có',
    value: 'co'
  },
  {
    text: 'Không',
    value: 'khong'
  },
  {
    text: 'Không ghi nhận',
    value: 'khong-ghi-nhan'
  }
]

export const AffectToPatient = [
  {
    text: 'Chưa xảy ra (NC0) - A',
    value: 'nc0-a'
  },
  {
    text: 'Tổn thương nhẹ (NC1) - B',
    value: 'nc1-b'
  },
  {
    text: 'Tổn thương nhẹ (NC1) - C',
    value: 'nc1-c'
  },
  {
    text: 'Tổn thương nhẹ (NC1) - D',
    value: 'nc1-d'
  },
  {
    text: 'Tổn thương trung bình (NC2) - E',
    value: 'nc2-e'
  },
  {
    text: 'Tổn thương trung bình (NC2) - F',
    value: 'nc2-f'
  },
  {
    text: 'Tổn thương nặng (NC3) - G',
    value: 'nc3-g'
  },
  {
    text: 'Tổn thương nặng (NC3) - H',
    value: 'nc3-h'
  },
  {
    text: 'Tổn thương nặng (NC3) - I',
    value: 'nc3-i'
  }
]

export const AffectToOrganization = [
  {
    text: 'Tổn hại tài sản',
    value: 'ton-hai-tai-san'
  },
  {
    text: 'Tăng nguồn lực phục vụ cho người bệnh',
    value: 'tang-nguon-luc-phuc-vu'
  },
  {
    text: 'Quan tâm của truyền thông',
    value: 'quan-tam-cua-truyen-thong'
  },
  {
    text: 'Khiếu nại của người bệnh',
    value: 'khieu-nai-cua-nguoi-benh'
  },
  {
    text: 'Tổn hại danh tiếng',
    value: 'ton-hai-danh-tieng'
  },
  {
    text: 'Can thiệp của pháp luật',
    value: 'can-thiep-cua-phap-luat'
  },
  {
    text: 'Khác',
    value: 'khac'
  }
]

export const AllOptions = [
  {
    text: 'Tất cả',
    value: ''
  },
]

export const getOptionsWithAll = (options: Option[]) => {
  return [
    ...AllOptions,
    ...options,
  ]
}

export const StatusOptions = [
  ...AllOptions,
  ...StatusOptionsList,
]

export const CONFIG_DATA = [
  {
    label: 'Ngày tạo',
    keyMapping: 'createdAt'
  },
  {
    label: 'Họ & tên',
    keyMapping: 'name'
  },
  {
    label: 'Số điện thoại',
    keyMapping: 'telephone'
  },
  {
    label: 'Ngày sự cố',
    keyMapping: 'incidentDate'
  },
  {
    label: 'Mô tả',
    keyMapping: 'description'
  },
  {
    label: 'Trạng thái',
    keyMapping: 'status'
  },
  {
    label: 'Người quản lý',
    keyMapping: 'assigned'
  },
  {
    label: '',
    keyMapping: 'actions'
  }
]

export const CONFIG_DATA_EMPLOYEE = [
  {
    label: 'Ngày tạo',
    keyMapping: 'createdAt'
  },
  {
    label: 'Người tạo',
    keyMapping: 'createdName'
  },
  {
    label: 'Ngày sự cố',
    keyMapping: 'incidentDate'
  },
  {
    label: 'Mô tả',
    keyMapping: 'description'
  },
  {
    label: 'Trạng thái',
    keyMapping: 'status'
  },
  {
    label: 'Ngày được giao',
    keyMapping: 'assignedAt'
  },
  {
    label: 'Người được giao',
    keyMapping: 'assigned'
  },
  {
    label: '',
    keyMapping: 'actions'
  }
]
