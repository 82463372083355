import React, { useMemo, useState, useEffect } from 'react';
import { getCssClass } from '../../../utils';
import Input from '../Input';
import './Select.scss';
import ClickOutside from '../../../hooks/useClickoutSide';

export interface Option {
  text: string;
  value: string;
  isDisabled?: boolean;
}

interface SelecProps {
  name: string,
  label: string,
  placeHolder?: string,
  defaultOption?: Option,
  options: Option[],
  isDisabled?: boolean;
  handleChanged: (selectedValue: {
    name: string,
    value: Option,
  }) => void;
}

const Select = ({
  name,
  label,
  placeHolder = 'Chọn',
  defaultOption,
  options,
  isDisabled,
  handleChanged
}: SelecProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const customOptions = useMemo(() => {
    if (!searchValue) {
      return options
    }

    return options.filter(
      item => item.text.toLowerCase().includes(searchValue.toLowerCase())
    )
  }, [searchValue, options])

  const hasSearch = useMemo(() => {
    return options.length > 20;
  }, [options]);

  const handleToggleDropdown = () => {
    setOpenDropdown((prev) => !prev)
  };

  const handleSelected = (option: Option) => {
    setSelectedValue(option)
    setOpenDropdown(false)
    handleChanged({
      name,
      value: option
    })
  };

  useEffect(() => {
    if (defaultOption) {
      setSelectedValue(defaultOption);
    }
  }, [defaultOption]);

  return (
    <ClickOutside
      className={getCssClass({
        '--opened': isOpenDropdown
      }, 'base-select')}
      handleCloseDropdown={() => setOpenDropdown(false)}
    >
      {label && <div className="base-select__label" >{label}</div>}
      <div
        className={getCssClass({
          '--disabled': !!isDisabled,
        }, 'base-select__heading')}
        onClick={handleToggleDropdown}
      >
        <div className="base-select__heading-label">
          { selectedValue?.text || placeHolder }
        </div>
        <i
          className={getCssClass({
            'fa-angle-down': !isOpenDropdown,
            'fa-angle-up': isOpenDropdown
          }, 'fa base-select__heading-icon')}
        />
      </div>
      {isOpenDropdown && (
        <div className="base-select__control">
          {hasSearch && (
            <Input
              label=""
              type="text"
              value=""
              name="searchOption"
              icon="fa-search"
              handleChange={(e: any) => setSearchValue(e.target.value)}
            />
          )}
          <ul className="base-select__control-list">
            {customOptions.map((option) => (
              <li
                key={option.value}
                className={getCssClass({
                  '--active': option.value === selectedValue?.value,
                  '--disabled': !!option.isDisabled,
                }, 'base-select__control-list__item')}
                onClick={() => handleSelected(option)}
              >
                {option.text}
              </li>
            ))}
            {!!searchValue && !customOptions[0] && (
              <li
                className="base-select__control-list__item --no-result"
              >Không có dữ liệu</li>
            )}
          </ul>
        </div>
      )}
    </ClickOutside>
  )
};

export default Select;
