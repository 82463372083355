import React from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Pages, EmployeePages, AdminPages } from './constants';
import './styles/index.scss';
import AuthenticationComponent from './components/AuthenticationComponent';

// user
import LoginPage from "./pages/login";
import Homepage from "./pages/homepage";
import RegisterPage from './pages/register';
import ChangePasswordPage from './pages/change-password';

// employee
import LoginPageEmployee from "./pages-employee/login";
import HomepageEmployee from "./pages-employee/homepage";
import RegisterPageEmployee from './pages-employee/register';
import ReportPageEmployee from './pages-employee/report';
import ChangePasswordEmployee from './pages-employee/change-password';

// admin
import HomeAdminpage from './pages-admin/homepage';
import LoginAdminPage from './pages-admin/login';
import RegisterAdminPage from './pages-admin/register';
import ReportAdminPage from './pages-admin/report';
import ChangePasswordAdmin from './pages-admin/change-password';
import ManageAccountsPage from './pages-admin/manage-accounts';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Router>
          <Switch>
            <AuthenticationComponent exact path={Pages.HOME} component={Homepage} />
            <AuthenticationComponent exact path={Pages.CHANGE_PASSWORD} component={ChangePasswordPage} />
            <Route exact path={Pages.LOGIN} render={() => <LoginPage />} />
            <Route exact path={Pages.REGISTER} render={() => <RegisterPage />} />

            <AuthenticationComponent exact path={EmployeePages.HOME} isEmployee component={HomepageEmployee} />
            <AuthenticationComponent exact path={EmployeePages.REPORT} isEmployee component={ReportPageEmployee} />
            <AuthenticationComponent exact path={EmployeePages.CHANGE_PASSWORD} isEmployee component={ChangePasswordEmployee} />
            <Route exact path={EmployeePages.LOGIN} render={() => <LoginPageEmployee />} />
            <Route exact path={EmployeePages.REGISTER} render={() => <RegisterPageEmployee />} />

            <AuthenticationComponent exact path={AdminPages.HOME} isAdmin component={HomeAdminpage} />
            <AuthenticationComponent exact path={AdminPages.REGISTER} isAdmin component={RegisterAdminPage} />
            <AuthenticationComponent exact path={AdminPages.REPORT} isAdmin component={ReportAdminPage} />
            <AuthenticationComponent exact path={AdminPages.CHANGE_PASSWORD} isAdmin component={ChangePasswordAdmin} />
            <AuthenticationComponent exact path={AdminPages.MANAGE_ACCOUNTS} isAdmin component={ManageAccountsPage} />
            <Route exact path={AdminPages.LOGIN} render={() => <LoginAdminPage />} />

            <Route exact path={Pages.NOT_FOUND} render={() => <div>Not Found Page</div>} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
