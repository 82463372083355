import React from 'react';
import { getCssClass } from '../../../utils';
import './Textarea.scss';

interface InputProps {
  label: string;
  name: string;
  placeHolder?: string;
  value: string;
  errorMsg?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  handleChange: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
}

const Textarea = ({
  label,
  name,
  placeHolder,
  value,
  errorMsg,
  isRequired,
  isDisabled,
  handleChange
}: InputProps) => {
  return (
    <div className="base-textarea">
      <div className="base-textarea__label">
        {label}
        {isRequired && ' *'}
      </div>
      <div
        className={getCssClass({
          '--disabled': !!isDisabled
        }, 'base-textarea__control')}
      >
        <textarea
          className='base-textarea__control-input'
          name={name}
          placeholder={placeHolder}
          value={value}
          onChange={handleChange}
        />
        {!!errorMsg && (
          <p className="base-input__error-message">{errorMsg.replace('{fieldName', name)}</p>
        )}
      </div>
    </div>
  )
};

export default Textarea;
