import React, { useState, useMemo, useEffect } from 'react'

import Button from '../common/Button'

import './create-popup.scss'
import Popup from '../common/Popup'
import Input from '../common/Input'
import Textarea from '../common/Textarea'
import Select, { Option } from '../common/Select'
import {
  AffectToOrganization,
  AffectToPatient,
  DiscussOption,
  EvaluateOptionsList,
  IncidentGroupOptions,
  IncidentReasonOptions,
  PlaceOptionsList,
  StatusOptionsList,
  TargetOptionsList,
  TypeOptionsList
} from '../../constants'
import { isEmpty } from 'lodash'
import { checkDisabledStatusItem } from '../../utils'
import Datepicker from '../common/Datepicker'
import RadioGroup from '../common/RadioGroup'
import MultiSelect from '../common/MultiSelect'

export interface DataForm {
  [keyMapping: string]: any;
}

export interface CreatePopupProps {
  isLoading: boolean;
  data: any;
  isEmployee?: boolean;
  UserNamesOptions?: Option[];
  EmployeesOptions?: Option[];
  handleSubmit: (dataForm: DataForm) => void;
  handleClose: () => void;
}

export default function CreatePopup ({
  isLoading,
  data,
  isEmployee,
  UserNamesOptions,
  EmployeesOptions,
  handleSubmit,
  handleClose
}: CreatePopupProps) {
  const [dataForm, setDataForm] = useState<DataForm>({
    coordinators: [],
  })

  const isCreate = useMemo(() => {
    return isEmpty(data);
  }, [data])

  const Options = useMemo(() => {
    return [...(EmployeesOptions || []), ...(UserNamesOptions || [])];
  }, [EmployeesOptions, UserNamesOptions]);

  function handleSubmitForm (e: any) {
    e.preventDefault()
    handleSubmit(dataForm)
  }

  function handleChangeInput (event: any) {
    const { value, name } = event.target;

    setDataForm((prevValue) => ({
      ...prevValue,
      [name]: value
    }))
  }

  useEffect(() => {
    if (!isCreate) {
      setDataForm({
        ...data,
        coordinators: data.coordinators || [],
      });
    }
  }, [isCreate, data])

  return (
    <Popup isShow close={handleClose}>
      <form
        className="component-create-popup__form"
        onSubmit={(e: any) => handleSubmitForm(e)}
      >
        <div className="component-create-popup__form-info">
          <Input
            type="text"
            name="name"
            label="Họ & tên"
            isDisabled={isEmployee}
            value={dataForm.name}
            handleChange={handleChangeInput}
          />
          <Input
            type="tel"
            name="telephone"
            label="Số điện thoại"
            isDisabled={isEmployee}
            value={dataForm.telephone}
            handleChange={handleChangeInput}
          />
          <Datepicker
            label="Thời điểm xảy ra sự cố"
            value={dataForm.incidentDate ? new Date(+dataForm.incidentDate) : null}
            isDisabled={isEmployee}
            handleSelect ={(date: Date) => {
              handleChangeInput({
                target: {
                  name: 'incidentDate',
                  value: +new Date(date),
                }
              })
            }}
            handleChange={() => {}}
          />
          <Select
            name="incidentTarget"
            label="Đối tượng xảy ra sự cố"
            defaultOption={TargetOptionsList.find(item => item.value === dataForm.incidentTarget)}
            options={TargetOptionsList}
            isDisabled={isEmployee}
            handleChanged={(selectedValue) => {
              setDataForm((prevValues) => ({
                ...prevValues,
                [selectedValue.name]: selectedValue.value.value
              }))
            }}
          />
          <Textarea
            name="person1"
            label="Người chứng kiến 1"
            isDisabled={isEmployee}
            value={dataForm.person1}
            handleChange={handleChangeInput}
          />
          <Textarea
            name="person2"
            label="Người chứng kiến 2"
            isDisabled={isEmployee}
            value={dataForm.person2}
            handleChange={handleChangeInput}
          />
          <Textarea
            name="incidentDetail"
            label="Thông tin cụ thể đối tượng xảy ra sự cố"
            isDisabled={isEmployee}
            value={dataForm.incidentDetail}
            handleChange={handleChangeInput}
          />
          <Select
            name="incidentPlace"
            label="Khoa/ phòng xảy ra sự cố"
            defaultOption={PlaceOptionsList.find(item => item.value === dataForm.incidentPlace)}
            options={PlaceOptionsList}
            isDisabled={isEmployee}
            handleChanged={(selectedValue) => {
              setDataForm((prevValues) => ({
                ...prevValues,
                [selectedValue.name]: selectedValue.value.value
              }))
            }}
          />
          <Textarea
            name="place"
            label="Vị trí cụ thể"
            isDisabled={isEmployee}
            value={dataForm.place}
            handleChange={handleChangeInput}
          />
          <Select
            name="incidentType"
            label="Phân loại ban đầu về sự cố"
            defaultOption={TypeOptionsList.find(item => item.value === dataForm.incidentType)}
            options={TypeOptionsList}
            isDisabled={isEmployee}
            handleChanged={(selectedValue) => {
              setDataForm((prevValues) => ({
                ...prevValues,
                [selectedValue.name]: selectedValue.value.value
              }))
            }}
          />
          <Select
            name="incidentEvaluate"
            label="Đánh giá ban đầu về mức độ ảnh hưởng của sự cố"
            defaultOption={EvaluateOptionsList.find(item => item.value === dataForm.incidentEvaluate)}
            options={EvaluateOptionsList}
            isDisabled={isEmployee}
            handleChanged={(selectedValue) => {
              setDataForm((prevValues) => ({
                ...prevValues,
                [selectedValue.name]: selectedValue.value.value
              }))
            }}
          />
          <Textarea
            name="description"
            label="Mô tả ngắn gọn sự cố"
            isDisabled={isEmployee}
            value={dataForm.description}
            handleChange={handleChangeInput}
          />
          <Textarea
            name="solution"
            label="Đề xuất giải pháp ban đầu"
            isDisabled={isEmployee}
            value={dataForm.solution}
            handleChange={handleChangeInput}
          />
          <Textarea
            name="treatmentUser"
            label="Điều trị/ Xử lý ban đầu đã được thực hiện"
            isDisabled={isEmployee}
            value={dataForm.treatmentUser}
            handleChange={handleChangeInput}
          />
          {isEmployee && (
            <>
              <div className="divider" />
              <p className="title">Phần xử lý của ban QLSC</p>
              <Textarea
                name="detail"
                label="Mô tả chi tiết sự cố"
                value={dataForm.detail}
                handleChange={handleChangeInput}
              />
              <Select
                name="incidentGroup"
                label="Phân nhóm sự cố"
                defaultOption={IncidentGroupOptions?.find(item => item.value === dataForm.incidentGroup)}
                options={IncidentGroupOptions || []}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
              <Textarea
                name="treatment"
                label="Điều trị/ y lệnh đã được thực hiện"
                value={dataForm.treatment}
                handleChange={handleChangeInput}
              />
              <Select
                name="incidentReason"
                label="Nguyên nhân gây ra sự cố"
                defaultOption={IncidentReasonOptions?.find(item => item.value === dataForm.incidentReason)}
                options={IncidentReasonOptions || []}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
              <Textarea
                name="result"
                label="Kết quả phát hiện"
                value={dataForm.result}
                handleChange={handleChangeInput}
              />
              <Textarea
                name="action"
                label="Hành động khắc phục sự cố"
                value={dataForm.action}
                handleChange={handleChangeInput}
              />
              <RadioGroup
                name="discuss"
                label="Đã thảo luận đưa khuyến cáo/ hướng xử lý với người báo cáo"
                options={DiscussOption || []}
                value={dataForm.discuss}
                handleChange={(value: string) => {
                  handleChangeInput({
                    target: {
                      name: 'discuss',
                      value,
                    }
                  })
                }}
              />
              <RadioGroup
                name="adapt"
                label="Phù hợp với các khuyến cáo chính thức được ban hành"
                options={DiscussOption || []}
                value={dataForm.adapt}
                handleChange={(value: string) => {
                  handleChangeInput({
                    target: {
                      name: 'adapt',
                      value,
                    }
                  })
                }}
              />
              <Textarea
                name="recomment"
                label="Ghi cụ thể khuyến cáo"
                value={dataForm.recomment}
                handleChange={handleChangeInput}
              />
              <Select
                name="affectToPatient"
                label="Ảnh hưởng trên người bệnh"
                defaultOption={AffectToPatient?.find(item => item.value === dataForm.affectToPatient)}
                options={AffectToPatient || []}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
              <Select
                name="affectToOrganization"
                label="Ảnh hưởng đối với tổ chức"
                defaultOption={AffectToOrganization?.find(item => item.value === dataForm.affectToOrganization)}
                options={AffectToOrganization || []}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
              <Select
                name="assigned"
                label="Khoa, phòng phụ trách"
                defaultOption={Options.find(item => item.value === dataForm.assigned)}
                options={Options.map(employee => ({
                  ...employee,
                  isDisabled: [...dataForm.coordinators].includes(employee.value)
                }))}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
              <Textarea
                name="assignedTitle"
                label="Người phụ trách"
                value={dataForm.assignedTitle}
                handleChange={handleChangeInput}
              />
              <MultiSelect
                name="coordinators"
                label="Khoa, phòng phối hợp"
                defaultOptions={Options.filter(item => dataForm.coordinators.includes(item.value))}
                options={Options.map(employee => ({
                  ...employee,
                  isDisabled: [dataForm.assigned].includes(employee.value)
                }))}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.map(item => item.value)
                  }))
                }}
              />
              <Textarea
                name="coordinatorTitle"
                label="Người phối hợp"
                value={dataForm.coordinatorTitle}
                handleChange={handleChangeInput}
              />
              <Textarea
                name="plan"
                label="Kế hoạch thực hiện"
                value={dataForm.plan}
                handleChange={handleChangeInput}
              />
              <Datepicker
                label="Dự kiến thời gian hoàn thành"
                value={dataForm.estimation ? new Date(+dataForm.estimation) : null}
                handleSelect ={(date: Date) => {
                  handleChangeInput({
                    target: {
                      name: 'estimation',
                      value: +new Date(date),
                    }
                  })
                }}
                handleChange={() => {}}
              />
              <Select
                name="status"
                label="Trạng thái"
                placeHolder="Lựa chọn"
                defaultOption={StatusOptionsList.find(item => item.value === dataForm.status)}
                options={StatusOptionsList.map((option, index) => ({
                  ...option,
                  isDisabled: checkDisabledStatusItem(
                    index,
                    StatusOptionsList.findIndex(item => item.value === data.status)
                  )
                }))}
                isDisabled={!dataForm.assigned}
                handleChanged={(selectedValue) => {
                  setDataForm((prevValues) => ({
                    ...prevValues,
                    [selectedValue.name]: selectedValue.value.value
                  }))
                }}
              />
            </>
          )}
        </div>
        <div className="component-create-popup__form-actions">
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            handleClick={handleClose}
          >
            Hủy bỏ
          </Button>
          <Button
            type="submit"
            classModifier="--blue"
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {isCreate ? 'Lưu' : isEmployee ? 'Cập nhật' : 'Chỉnh sửa' }
          </Button>
        </div>
      </form>
    </Popup>
  )
}
