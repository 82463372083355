import React from 'react';
import { useHistory } from 'react-router-dom'
import { getCookie } from '../utils'
import { LOGIN_COOKIE, Pages } from '../constants'
import Login from '../components/Login';

const LoginPage = () => {
  const history = useHistory()
  const isLoggedIn = !!getCookie(LOGIN_COOKIE)

  if (isLoggedIn) {
    history.push(Pages.HOME);
  }

  return <Login />
};

export default LoginPage;
