import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './Homepage.scss';
import Select, { Option } from '../common/Select';
import { CONFIG_DATA, LOGIN_COOKIE, StatusOptions, StatusOptionsList } from '../../constants';
import Table from '../common/Table';
import Button from '../common/Button';
import CreatePopup, { DataForm } from '../CreatePopup';
import { postService } from '../../utils/service';
import Toast from '../common/Toast';
import Loader from '../common/Loader';
import { isEmpty } from 'lodash';
import { getCookie, getCssClass, parseJwt } from '../../utils';
import Header from '../Header';

const Homepage = () => {
  const [filterValues, setFilterValues] = useState<{
    status: Option
  }>({
    status: StatusOptions[0]
  })
  const [isAddModal, setAddModal] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const loggedInInfo = useMemo(() => {
    return parseJwt(getCookie(LOGIN_COOKIE))
  }, [])

  const renderFilterGroup = useMemo(() => {
    return (
      <div className="homepage-component__filter-group">
        <Select
          name="status"
          label="Trạng thái"
          placeHolder="Lựa chọn"
          defaultOption={filterValues.status}
          options={StatusOptions}
          handleChanged={(selectedValue) => {
            setFilterValues((prevValues) => ({
              ...prevValues,
              [selectedValue.name]: selectedValue.value
            }))
          }}
        />
      </div>
    );
  }, [filterValues.status]);

  const renderActons = useCallback((row: any) => {
    return (
      <div className="homepage-component__group-actions">
        <i
          className={getCssClass({
            '--disabled': ![StatusOptionsList[0].value].includes(row.status)
          }, 'fa fa-pencil homepage-component__group-actions-item')}
          title="Edit"
          onClick={() => {
            setCurrentData(row);
            setAddModal(true);
          }}
        />
        {/* <i
          className="fa fa-trash homepage-component__group-actions-item"
          title="Remove"
        /> */}
      </div>
    )
  }, [])

  const fetchList = useCallback(async () => {
    setLoading(true);

    const { data, error } = await postService('requirement/get', {
      createdName: loggedInInfo.userName,
      status: filterValues.status.value,
    }, {
      'access-token': getCookie(LOGIN_COOKIE)
    }) as any;

    setLoading(false);

    if (error) {
      return;
    }

    setData(data.data);
  }, [loggedInInfo, filterValues])

  const handleSubmit = useCallback(async (dataForm: DataForm) => {
    setLoading(true);

    const isCreate = isEmpty(currentData);
    const { error } = await postService(
      `requirement/${isCreate ? 'create' : 'update'}`,
      isCreate ? {
        ...dataForm,
        createdName: loggedInInfo.userName,
      } : dataForm,
      {
        'access-token': getCookie(LOGIN_COOKIE)
      }
    ) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setAddModal(false);
    setCurrentData(null);
    setMsg(isCreate ? 'Tạo thành công.' : 'Chỉnh sửa thành công.');
    fetchList();
  }, [loggedInInfo, currentData, fetchList])

  const renderContent = useMemo(() => {
    return (
      <div className="homepage-component__content">
        <Button handleClick={() => setAddModal(true)}>Thêm</Button>
        <Table configData={CONFIG_DATA} data={data}>{renderActons}</Table>
        {isLoading && <Loader />}
      </div>
    );
  }, [data, isLoading, renderActons]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div className="homepage-component">
      <Header />
      <div className="section">
        <h2 className="section__title">Trang quản lý</h2>
        {renderFilterGroup}
        {renderContent}
        {isAddModal && (
          <CreatePopup
            isLoading={isLoading}
            data={currentData}
            handleSubmit={handleSubmit}
            handleClose={() => {
              setAddModal(false)
              setCurrentData(null)
            }}
          />
        )}
        <Toast
          msg={msg}
          icon={hasError ? "fa-warning" : "fa-check-circle"}
          onClosed={() => setMsg('')}
        />
      </div>
    </div>
  );
};

export default Homepage;
