import React, { useEffect } from 'react';
import { getCssClass } from '../../../utils';
import './Toast.scss';

interface ToastProps {
  msg: string;
  icon?: string;
  timeout?: number;
  onClosed: () => void;
}

const Toast = ({ msg, icon, timeout = 3000, onClosed }: ToastProps) => {
  useEffect(() => {
    const timeoutToast = setTimeout(() => {
      onClosed();
    }, timeout)

    return () => {
      clearTimeout(timeoutToast)
    }
  }, [timeout, onClosed])

  if (!msg) {
    return null;
  }

  return (
    <div className="toast-component">
      <div className="toast-component__inner">
        {!!icon && (
          <i className={getCssClass({
            [icon]: true
          }, 'fa toast-component__icon')} />
        )}
        <p className="toast-component__content">{msg}</p>
        <i className="fa fa-close" onClick={onClosed} />
      </div>
    </div>
  )
};

export default Toast;
