import React, { useRef, useEffect } from "react";

function useClickoutSide(ref: any, handleCloseDropdown: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleCloseDropdown();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleCloseDropdown]);
}

interface ClickOutsideProps {
  className: string;
  children: React.ReactNode;
  handleCloseDropdown: () => void;
}

export default function ClickOutside({ className, children, handleCloseDropdown }: ClickOutsideProps) {
  const wrapperRef = useRef(null);

  useClickoutSide(wrapperRef, handleCloseDropdown);

  return <div ref={wrapperRef} className={className}>{children}</div>;
}
