import React from 'react'

import './table.scss'
import { StatusOptionsList } from '../../../constants';
import moment from 'moment';

interface configData {
  label: string,
  keyMapping: string
}

interface datum {
  [keyMapping: string]: string
}

interface TableProps {
  configData: Array<configData>;
  data: Array<datum>;
  children: (row: datum) => React.ReactNode;
}

export default function Table ({ configData, data, children }: TableProps) {
  return (
    <div className="component-table__wrapper">
      <table className="component-table">
        <thead className="component-table__head">
          <tr className="component-table__tr">
            {
              configData.map(({ label }, index) =>
                <td key={index} className="component-table__td">{label}</td>
              )
            }
          </tr>
        </thead>
        <tbody className="component-table__body">
          {data.map((item, index) =>
              <tr key={index} className="component-table__tr">
                {
                  configData.map(({ keyMapping }, idx) =>
                    <td key={idx} className="component-table__td">
                      {keyMapping === 'actions'
                        ? children(item)
                        : ['createdAt', 'assignedAt', 'incidentDate'].includes(keyMapping) && item[keyMapping]
                          ? moment(item[keyMapping]).format('DD-MM-YYYY')
                          : (['status'].includes(keyMapping)
                            && (keyMapping === 'status' ? StatusOptionsList : [])
                              .find(option => option.value === item[keyMapping])?.text)
                            || item[keyMapping]
                            || '---'
                      }
                    </td>
                  )
                }
              </tr>
            )
          }
        </tbody>
      </table>
      {!data[0] && (
        <div className="component-table__no-result-wrapper">
          <p className="component-table__no-result">Không tìm thấy dữ liệu</p>
        </div>
      )}
    </div>
  )
}
