import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getCookie } from '../../utils'
import {
  LOGIN_COOKIE,
  LOGIN_EMPLOYEE_COOKIE,
  LOGIN_ADMIN_COOKIE,
  Pages,
  EmployeePages,
  AdminPages
} from '../../constants'

const AuthenticationComponent = ({
  component: Component,
  path,
  isAdmin,
  isEmployee,
  ...rest
}: any) => {
  const isLoggedIn = !!getCookie(
    isAdmin
      ? LOGIN_ADMIN_COOKIE
      : isEmployee
        ? LOGIN_EMPLOYEE_COOKIE
        : LOGIN_COOKIE
  )

  return (
    <Route
      {...rest}
      path={path}
      render={props =>
        isLoggedIn ? (
          <Component />
        ) : (
          <Redirect to={{
            pathname: isAdmin
              ? AdminPages.LOGIN
              : isEmployee
                ? EmployeePages.LOGIN
                : Pages.LOGIN,
            state: { from: props.location }
          }} />
        )
      }
    />
  )
}

export default AuthenticationComponent
