import React from 'react';
import './Popup.scss';

interface PopupProps {
  isShow: boolean;
  children: React.ReactNode
  close: () => void;
}

const Popup = ({ isShow, children, close }: PopupProps) => {
  if (!isShow) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup__overlay" onClick={close} />
      <div className="popup__content">
        {children}
      </div>
    </div>
  )
}

export default Popup;
