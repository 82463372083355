import React from 'react';

import './RadioGroup.scss';

export interface RadioOption {
  text: string;
  value: string;
}

interface RadioGroupProps {
  label: string;
  name: string;
  value?: string;
  options: RadioOption[];
  isDisabled?: boolean;
  handleChange: (value: string) => void;
}

const RadioGroup = ({
  label,
  name,
  value,
  options,
  isDisabled,
  handleChange,
 }: RadioGroupProps) => {
  return (
    <div className="base-radio-group">
      {label && <div className="base-radio-group__label">{label}</div>}
      <div className="base-radio-group__group-item">
        {options.map(item => {
          return (
            <label key={item.value} className="base-radio-group__item">
              <input
                type="radio"
                className="base-radio-group__item-input"
                id={item.value}
                name={name}
                value={item.value}
                checked={item.value === value}
                onChange={(evt) => {
                  handleChange(evt.target.value)
                }}
                disabled={isDisabled}
              />
              <span className="base-radio-group__item-text">{item.text}</span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default RadioGroup;
