import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Input from '../common/Input';
import Button from '../common/Button';
import './Register.scss';
import Toast from '../common/Toast';
import { EmployeePages } from '../../constants';
import { postService } from '../../utils/service';

const Register = () => {
  const history = useHistory()

  const [values, setValues] = useState<{
    userName: string;
    password: string;
    rePassword: string;
  }>({
    userName: '',
    password: '',
    rePassword: '',
  })
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;

    setValues((prevValue) => ({
      ...prevValue,
      [name]: value
    }))
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const { error } = await postService('employee-register', values) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setMsg('Đăng ký thành công.')
    history.push(EmployeePages.LOGIN);
  }, [values, history]);

  return <div className="section register-component">
    <h2 className="section__title">Trang đăng ký</h2>
    <form className="register-component__form" onSubmit={handleSubmit}>
      <Input
        type="text"
        name="userName"
        label="Tên đăng nhập"
        value={values.userName}
        handleChange={handleChange}
      />
      <Input
        type="password"
        name="password"
        label="Mật khẩu"
        value={values.password}
        handleChange={handleChange}
      />
      <Input
        type="password"
        name="rePassword"
        label="Nhập lại mật khẩu"
        value={values.rePassword}
        handleChange={handleChange}
      />
      <p
        className="link"
        onClick={() => history.push(EmployeePages.LOGIN)}
      >Trang đăng nhập</p>
      <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>Đăng ký</Button>
    </form>
    <Toast
      msg={msg}
      icon={hasError ? "fa-warning" : "fa-check-circle"}
      onClosed={() => setMsg('')}
    />
  </div>;
};

export default Register;
