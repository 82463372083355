import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './Homepage.scss';
import Select, { Option } from '../common/Select';
import {
  AllOptions,
  CONFIG_DATA_EMPLOYEE,
  LOGIN_ADMIN_COOKIE,
  StatusOptions,
  getOptionsWithAll,
} from '../../constants';
import Table from '../common/Table';
import CreatePopup, { DataForm } from '../CreatePopup';
import { getService, postService, deleteService } from '../../utils/service';
import Toast from '../common/Toast';
import Loader from '../common/Loader';
import { getCookie, getCssClass } from '../../utils';
import Header from '../Header';
import Popup from '../common/Popup';
import Button from '../common/Button';

const Homepage = () => {
  const [filterValues, setFilterValues] = useState<{
    createdName: Option,
    employee: Option,
    status: Option
  }>({
    createdName: AllOptions[0],
    employee: AllOptions[0],
    status: StatusOptions[0]
  })
  const [isAddModal, setAddModal] = useState<boolean>(false);
  const [employees, setEmployees] = useState<string[]>([]);
  const [userNames, setUserNames] = useState<string[]>([]);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isRemoveModal, setRemoveModal] = useState<boolean>(false);
  const [isLoadingRemove, setLoadingRemove] = useState<boolean>(false);

  const EmployeesOptions = useMemo(() => {
    return [
      { text: 'admin', value: 'admin' },
      ...employees.map((employee) => ({
        text: employee,
        value: employee,
      }))
    ]
  }, [employees])

  const EmployeesOptionsWithAllOption = useMemo(() => {
    return getOptionsWithAll(EmployeesOptions)
  }, [EmployeesOptions])

  const UserNamesOptions = useMemo(() => {
    return [...userNames.map((employee) => ({
      text: employee,
      value: employee,
    }))]
  }, [userNames])

  const UserNamesOptionsWithAllOption = useMemo(() => {
    return getOptionsWithAll(UserNamesOptions);
  }, [UserNamesOptions])

  const renderFilterGroup = useMemo(() => {
    return (
      <div className="homepage-component__filter-group">
        <Select
          name="createdName"
          label="Tên người dùng"
          placeHolder="Lựa chọn"
          defaultOption={filterValues.createdName}
          options={UserNamesOptionsWithAllOption}
          handleChanged={(selectedValue) => {
            setFilterValues((prevValues) => ({
              ...prevValues,
              [selectedValue.name]: selectedValue.value
            }))
          }}
        />
        <Select
          name="status"
          label="Trạng thái"
          placeHolder="Lựa chọn"
          defaultOption={filterValues.status}
          options={StatusOptions}
          handleChanged={(selectedValue) => {
            setFilterValues((prevValues) => ({
              ...prevValues,
              [selectedValue.name]: selectedValue.value
            }))
          }}
        />
        <Select
          name="employee"
          label="Người được giao"
          placeHolder="Lựa chọn"
          defaultOption={filterValues.employee}
          options={EmployeesOptionsWithAllOption}
          handleChanged={(selectedValue) => {
            setFilterValues((prevValues) => ({
              ...prevValues,
              [selectedValue.name]: selectedValue.value
            }))
          }}
        />
      </div>
    );
  }, [
    filterValues,
    UserNamesOptionsWithAllOption,
    EmployeesOptionsWithAllOption
  ]);

  const renderActons = useCallback((row: any) => {
    return (
      <div className="homepage-component__group-actions">
        <i
          className={getCssClass({
            '--disabled': ['3'].includes(row.status)
          }, 'fa fa-pencil homepage-component__group-actions-item')}
          title="Chỉnh sửa"
          onClick={() => {
            setCurrentData(row);
            setAddModal(true);
          }}
        />
        <i
          className="fa fa-trash homepage-component__group-actions-item"
          title="Xóa"
          onClick={() => {
            setCurrentData(row);
            setRemoveModal(true);
          }}
        />
      </div>
    )
  }, [])

  const fetchEmployees = useCallback(async () => {
    const { data, error } = await getService('employees/get') as any;

    if (error) {
      return;
    }

    setEmployees(data.data);
  }, [])

  const fetchUserNames = useCallback(async () => {
    const { data, error } = await getService('users/get') as any;

    if (error) {
      return;
    }

    setUserNames(data.data);
  }, [])

  const fetchList = useCallback(async () => {
    setLoading(true);

    const { data, error } = await postService('requirement/get', {
      createdName: filterValues.createdName.value,
      employee: filterValues.employee.value,
      status: filterValues.status.value,
    }, {
      'access-token': getCookie(LOGIN_ADMIN_COOKIE)
    }) as any;

    setLoading(false);

    if (error) {
      return;
    }

    setData(data.data);
  }, [filterValues])

  const handleSubmit = useCallback(async (dataForm: DataForm) => {
    setLoading(true);

    const { error } = await postService(
      `requirement/update-by-employee`,
      dataForm,
      {
        'access-token': getCookie(LOGIN_ADMIN_COOKIE)
      }
    ) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setAddModal(false);
    setCurrentData(null);
    setMsg('Chỉnh sửa thành công.');
    fetchList();
  }, [fetchList])

  const handleRemove = useCallback(async () => {
    setLoadingRemove(true);

    const { error } = await deleteService(
      `requirement/delete`,
      {
        id: currentData?.id,
      },
      {
        'access-token': getCookie(LOGIN_ADMIN_COOKIE)
      }
    ) as any;

    setLoadingRemove(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setRemoveModal(false);
    setCurrentData(null);
    setMsg('Xóa thành công.');
    fetchList();
  }, [fetchList, currentData])

  const renderContent = useMemo(() => {
    return (
      <div className="homepage-component__content">
        <Table configData={CONFIG_DATA_EMPLOYEE} data={data}>{renderActons}</Table>
        {isLoading && <Loader />}
      </div>
    );
  }, [data, isLoading, renderActons]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => {
    fetchUserNames();
  }, [fetchUserNames]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div className="homepage-component">
      <Header isAdmin />
      <div className="section">
        <h2 className="section__title">Trang quản lý</h2>
        {renderFilterGroup}
        {renderContent}
        {isAddModal && (
          <CreatePopup
            isLoading={isLoading}
            data={currentData}
            isEmployee
            UserNamesOptions={UserNamesOptions}
            EmployeesOptions={EmployeesOptions}
            handleSubmit={handleSubmit}
            handleClose={() => {
              setAddModal(false)
              setCurrentData(null)
            }}
          />
        )}
        {isRemoveModal && (
          <Popup
            isShow
            close={() => {
              setRemoveModal(false)
              setCurrentData(null)
            }}
          >
            <div className="remove-popup">
              <p className="remove-popup__title">Xóa dữ liệu?</p>
              <p className="remove-popup__description">Bạn có chắc muốn xóa dữ liệu?</p>
              <div className="remove-popup__actions">
                <Button
                  isLoading={isLoadingRemove}
                  isDisabled={isLoadingRemove}
                  handleClick={() => {
                    setRemoveModal(false)
                    setCurrentData(null)
                  }}
                >
                  Hủy bỏ
                </Button>
                <Button
                  classModifier="--blue"
                  isLoading={isLoadingRemove}
                  isDisabled={isLoadingRemove}
                  handleClick={handleRemove}
                >
                  Xóa
                </Button>
              </div>
            </div>
          </Popup>
        )}
        <Toast
          msg={msg}
          icon={hasError ? "fa-warning" : "fa-check-circle"}
          onClosed={() => setMsg('')}
        />
      </div>
    </div>
  );
};

export default Homepage;
