import React  from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';

type DateTime = Date | null | undefined;

interface DatepickerProps {
  value?: DateTime;
  label: string;
  placeholder?: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  readOnly?: boolean;
  dateFormat?: string;
  isDisabled?: boolean;
  handleChange: (date: Date, event: any) => void;
  handleSelect: (date: Date, event: any) => void;
}

const Datepicker = ({
  value,
  label,
  placeholder = "Chọn ngày",
  minDate,
  maxDate,
  readOnly,
  dateFormat = 'dd-MM-yyyy',
  isDisabled,
  handleChange,
  handleSelect
}: DatepickerProps) => {
  return (
    <div className="base-datepicker">
      <div className="base-datepicker__label">
        {label}
      </div>
      <DatePicker
        className="base-datepicker__input"
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        readOnly={readOnly}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        disabled={isDisabled}
        onSelect={handleSelect}
        onChange={handleChange}
      />
    </div>
  )
}

export default Datepicker;
