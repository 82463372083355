import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getCookie, getPreviousMonth } from '../../utils';
import { postService } from '../../utils/service';
import { AffectToOrganization, AffectToPatient, AllOptions, DiscussOption, EvaluateOptionsList, IncidentGroupOptions, IncidentReasonOptions, LOGIN_ADMIN_COOKIE, LOGIN_COOKIE, LOGIN_EMPLOYEE_COOKIE, PlaceOptionsList, StatusOptions, StatusOptionsList, TargetOptionsList, TypeOptionsList, getOptionsWithAll } from '../../constants';
import Loader from '../common/Loader';
import { groupBy } from 'lodash';
import './Report.scss';
import Header from '../Header';
import { CSVLink } from "react-csv";
import moment from 'moment';
import Select, { Option } from '../common/Select';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface ReportProps {
  isAdmin?: boolean;
  isEmployee?: boolean;
}

const Report = ({ isAdmin, isEmployee }: ReportProps) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<Option>(AllOptions[0]);

  const filteredData = useMemo(() => {
    return data.filter(datum => datum.createdAt >= +currentOption.value)
  }, [data, currentOption])

  const headers = useMemo(() => {
    return [
      { label: "Ngày tạo", key: "createdAt" },
      { label: "Người tạo", key: "name" },
      { label: "Số điện thoại", key: "telephone" },
      { label: "Thời điểm xảy ra sự cố", key: "incidentDate" },
      { label: "Đối tượng xảy ra sự cố", key: "incidentTarget" },
      { label: "Người chứng kiến 1", key: "person1" },
      { label: "Người chứng kiến 2", key: "person2" },
      { label: "Thông tin cụ thể đối tượng xảy ra sự cố", key: "incidentDetail" },
      { label: "Khoa/ phòng xảy ra sự cố", key: "incidentPlace" },
      { label: "Vị trí cụ thể", key: "place" },
      { label: "Phân loại ban đầu về sự cố", key: "incidentType" },
      { label: "Đánh giá ban đầu về mức độ ảnh hưởng của sự cố", key: "incidentEvaluate" },
      { label: "Mô tả ngắn gọn sự cố", key: "description" },
      { label: "Đề xuất giải pháp ban đầu", key: "solution" },
      { label: "Điều trị/ Xử lý ban đầu đã được thực hiện", key: "treatmentUser" },
      { label: "Mô tả chi tiết sự cố", key: "detail" },
      { label: "Phân nhóm sự cố", key: "incidentGroup" },
      { label: "Điều trị/ y lệnh đã được thực hiện", key: "treatment" },
      { label: "Nguyên nhân gây ra sự cố", key: "incidentReason" },
      { label: "Kết quả phát hiện", key: "result" },
      { label: "Hành động khắc phục sự cố", key: "action" },
      { label: "Đã thảo luận đưa khuyến cáo/ hướng xử lý với người báo cáo", key: "discuss" },
      { label: "Phù hợp với các khuyến cáo chính thức được ban hành", key: "adapt" },
      { label: "Ghi cụ thể khuyến cáo", key: "recomment" },
      { label: "Ảnh hưởng trên người bệnh", key: "affectToPatient" },
      { label: "Ảnh hưởng đối với tổ chức", key: "affectToOrganization" },
      { label: "Người phụ trách", key: "assigned" },
      { label: "Chức danh người phụ trách", key: "assignedTitle" },
      { label: "Người phối hợp", key: "coordinators" },
      { label: "Chức danh người phối hợp", key: "coordinatorTitle" },
      { label: "Kế hoạch thực hiện", key: "plan" },
      { label: "Dự kiến thời gian hoàn thành", key: "estimation" },
      { label: "Trạng thái", key: "status" },
    ]
  }, [])

  const csvData = useMemo(() => {
    return filteredData.map((datum) => {
      const convertedDatum = {
        createdAt: moment(datum.createdAt).format('DD-MM-YYYY'),
        name: datum.name,
        telephone: datum.telephone,
        incidentDate: moment(datum.incidentDate).format('DD-MM-YYYY'),
        incidentTarget: TargetOptionsList.find(item => item.value === datum.incidentTarget)?.text,
        incidentPlace: PlaceOptionsList.find(item => item.value === datum.incidentPlace)?.text,
        place: datum.place,
        incidentType: TypeOptionsList.find(item => item.value === datum.incidentType)?.text,
        incidentEvaluate: EvaluateOptionsList.find(item => item.value === datum.incidentEvaluate)?.text,
        description: datum.description,
        solution: datum.solution,
        detail: datum.detail,
        incidentGroup: IncidentGroupOptions.find(item => item.value === datum.incidentGroup)?.text,
        treatment: datum.treatment,
        incidentReason: IncidentReasonOptions.find(item => item.value === datum.incidentReason)?.text,
        result: datum.result,
        action: datum.action,
        discuss: DiscussOption.find(item => item.value === datum.discuss)?.text,
        adapt: DiscussOption.find(item => item.value === datum.adapt)?.text,
        recomment: datum.recomment,
        affectToPatient: AffectToPatient.find(item => item.value === datum.affectToPatient)?.text,
        affectToOrganization: AffectToOrganization.find(item => item.value === datum.affectToOrganization)?.text,
        assigned: datum.assigned,
        assignedTitle: datum.assignedTitle,
        coordinators: datum.coordinators.join(', '),
        coordinatorTitle: datum.coordinatorTitle,
        plan: datum.plan,
        status: StatusOptionsList.find(option => option.value === datum.status)?.text,
      }
      return convertedDatum;
    })
  }, [filteredData])

  const dataChart = useMemo(() => {
    const groupByTarget = groupBy(filteredData, 'incidentTarget');
    const dataChart = TargetOptionsList.map((option) => {
      const value = groupByTarget[option.value]?.length || 0;
      const percent = (value / (filteredData.length || 1) * 100).toFixed(2);

      return {
        text: `${option.text}: ${value} (${percent}%)`,
        value,
      }
    })

    return {
      labels: dataChart.map(datum => datum.text),
      datasets: [
        {
          label: '# of Votes',
          data: dataChart.map(datum => datum.value),
          backgroundColor: TargetOptionsList.map(option => option.backgroundColor),
        },
      ],
    };
  }, [filteredData]);

  const dataChart2 = useMemo(() => {
    const groupByTarget = groupBy(filteredData, 'status');
    const dataChart = StatusOptionsList.map((option) => {
      const value = groupByTarget[option.value]?.length || 0;
      const percent = (value / (filteredData.length || 1) * 100).toFixed(2);

      return {
        text: `${option.text}: ${value} (${percent}%)`,
        value,
      }
    })

    return {
      labels: dataChart.map(datum => datum.text),
      datasets: [
        {
          label: '# of Votes',
          data: dataChart.map(datum => datum.value),
          backgroundColor: StatusOptionsList.map(option => option.backgroundColor),
        },
      ],
    };
  }, [filteredData]);

  const ReportOptions = useMemo(() => {
    return [
      {
        text: '6 tháng',
        value: String(+getPreviousMonth(new Date(), 6))
      },
      {
        text: '3 tháng',
        value: String(+getPreviousMonth(new Date(), 3))
      },
      {
        text: '1 tháng',
        value: String(+getPreviousMonth(new Date(), 1))
      },
    ]
  }, [])

  const fetchList = useCallback(async () => {
    setLoading(true);

    const { data, error } = await postService('requirement/get', {
      createdName: AllOptions[0].value,
      employee: AllOptions[0].value,
      status: StatusOptions[0].value
    }, {
      'access-token': getCookie(isAdmin ? LOGIN_ADMIN_COOKIE : isEmployee ? LOGIN_EMPLOYEE_COOKIE : LOGIN_COOKIE)
    }) as any;

    setLoading(false);

    if (error) {
      return;
    }

    setData(data.data);
  }, [isAdmin, isEmployee])

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div className="report-component">
      <Header isAdmin={isAdmin} isEmployee={isEmployee} />
      <div className="report-component__body">
        <div className="report-component__body-action">
          <Select
            name="reportType"
            label="Thời gian"
            defaultOption={currentOption}
            options={getOptionsWithAll(ReportOptions)}
            handleChanged={(selectedValue) => setCurrentOption(selectedValue.value)}
          />
          <CSVLink className="btn" filename="bao-cao.csv" data={csvData} headers={headers}>
            Xuất dữ liệu
          </CSVLink>
        </div>
        <div className="report-component__chart-group__wrapper">
          <div className="report-component__chart-group">
            <p className="report-component__chart-group__title">Đối tượng</p>
            {dataChart.datasets[0].data.length ? <Doughnut data={dataChart} /> : (
              <p className="report-component__chart-group__title">Không tìm thấy dữ liệu</p>
            )}
          </div>
          <div className="report-component__chart-group">
            <p className="report-component__chart-group__title">Tình trạng xử lý</p>
            {dataChart2.datasets[0].data.length ? <Doughnut data={dataChart2} /> : (
              <p className="report-component__chart-group__title">Không tìm thấy dữ liệu</p>
            )}
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </div>
  )
}

export default Report;
