import axios from 'axios'

const DEFAULT_HEADERS = {}

// const BASE_URL = 'http://localhost:3001/' // for dev
const BASE_URL = 'https://manage-g0au.onrender.com/';

const getURL = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://'))  {
    return url;
  }

  return `${BASE_URL}${url}`
}

export const getService = (url = '', params = {}, headers = {} as any, options = {}) => {
  return axios.get(getURL(url), {
    ...options,
    params,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers
    }
  })
    .then(({ status, data }) => {
      return {
        status,
        data
      }
    })
    .catch(error => {
      console.error(error)

      if (!error) {
        return {
          status: '504',
          error: {
            data: {
              message: 'Request Timeout'
            }
          }
        }
      }

      if (!error.response) {
        return {
          status: '500',
          error: {
            data: {
              message: 'Server error'
            }
          }
        }
      }

      const { status, data } = error.response

      return {
        error: {
          status,
          data
        }
      }
    })
}

export const postService = (url = '', data = {}, headers = {} as any, options = {}) => {
  return axios.post(getURL(url), data, {
    ...options,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers
    }
  })
    .then(({ status, data }) => {
      return {
        status,
        data
      }
    })
    .catch(error => {
      console.error(error)

      if (!error) {
        return {
          status: '504',
          error: {
            data: {
              message: 'Request Timeout'
            }
          }
        }
      }

      if (!error.response) {
        return {
          status: '500',
          error: {
            data: {
              message: 'Server error'
            }
          }
        }
      }

      const { status, data } = error.response

      return {
        error: {
          status,
          data
        }
      }
    })
}

export const deleteService = (url = '', data = {}, headers = {} as any, options = {}) => {
  return axios.delete(getURL(url), {
    ...options,
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers
    }
  })
    .then(({ status, data }) => {
      return {
        status,
        data
      }
    })
    .catch(error => {
      console.error(error)

      if (!error) {
        return {
          status: '504',
          error: {
            data: {
              message: 'Request Timeout'
            }
          }
        }
      }

      if (!error.response) {
        return {
          status: '500',
          error: {
            data: {
              message: 'Server error'
            }
          }
        }
      }

      const { status, data } = error.response

      return {
        error: {
          status,
          data
        }
      }
    })
}
