import React, { useMemo, useState, useEffect } from 'react';
import { getCssClass } from '../../../utils';
import Input from '../Input';
import './MultiSelect.scss';
import ClickOutside from '../../../hooks/useClickoutSide';
import { cloneDeep } from 'lodash';

export interface Option {
  text: string;
  value: string;
  isDisabled?: boolean;
}

interface MultiSelectProps {
  name: string,
  label: string,
  placeHolder?: string,
  defaultOptions?: Option[],
  options: Option[],
  isDisabled?: boolean;
  handleChanged: (selectedValue: {
    name: string,
    value: Option[],
  }) => void;
}

const MultiSelect = ({
  name,
  label,
  placeHolder = 'Chọn',
  defaultOptions,
  options,
  isDisabled,
  handleChanged
}: MultiSelectProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const customOptions = useMemo(() => {
    if (!searchValue) {
      return options
    }

    return options.filter(
      item => item.text.toLowerCase().includes(searchValue.toLowerCase())
    )
  }, [searchValue, options])

  const hasSearch = useMemo(() => {
    return options.length > 20;
  }, [options]);

  const handleToggleDropdown = () => {
    setOpenDropdown((prev) => !prev)
  };

  const handleSelected = (option: Option) => {
    setSelectedValues((prevValues) => {
      const cloneData = cloneDeep(prevValues);
      const indexSelectedValue = cloneData.findIndex(datum => datum.value === option.value);

      if (indexSelectedValue !== -1) {
        cloneData.splice(indexSelectedValue, 1);
      } else {
        cloneData.push(option);
      }

      handleChanged({
        name,
        value: cloneData
      })

      return cloneData
    });
  };

  useEffect(() => {
    if (defaultOptions) {
      setSelectedValues(defaultOptions);
    }
  }, [defaultOptions]);

  return (
    <ClickOutside
      className={getCssClass({
        '--opened': isOpenDropdown
      }, 'base-select')}
      handleCloseDropdown={() => setOpenDropdown(false)}
    >
      {label && <div className="base-select__label" >{label}</div>}
      <div
        className={getCssClass({
          '--disabled': !!isDisabled,
        }, 'base-select__heading')}
        onClick={handleToggleDropdown}
      >
        <div className="base-select__heading-label">
          { selectedValues.map(value => value.text).join(', ') || placeHolder }
        </div>
        <i
          className={getCssClass({
            'fa-angle-down': !isOpenDropdown,
            'fa-angle-up': isOpenDropdown
          }, 'fa base-select__heading-icon')}
        />
      </div>
      {isOpenDropdown && (
        <div className="base-select__control">
          {hasSearch && (
            <Input
              label=""
              type="text"
              value=""
              name="searchOption"
              icon="fa-search"
              handleChange={(e: any) => setSearchValue(e.target.value)}
            />
          )}
          <ul className="base-select__control-list">
            {customOptions.map((option) => (
              <li
                key={option.value}
                className={getCssClass({
                  '--active': selectedValues.some(value => value.value === option.value),
                  '--disabled': !!option.isDisabled,
                }, 'base-select__control-list__item')}
                onClick={() => handleSelected(option)}
              >
                {option.text}
              </li>
            ))}
            {!!searchValue && !customOptions[0] && (
              <li
                className="base-select__control-list__item --no-result"
              >Không có dữ liệu</li>
            )}
          </ul>
        </div>
      )}
    </ClickOutside>
  )
};

export default MultiSelect;
