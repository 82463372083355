import React, { useMemo, useState } from 'react';
import { getCssClass } from '../../../utils';
import './input.scss';

interface InputProps {
  label: string;
  name: string;
  placeHolder?: string;
  icon?: string;
  type: string;
  value: string;
  errorMsg?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  handleChange: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
}

const Input = ({
  label,
  name,
  icon,
  placeHolder,
  type,
  value,
  errorMsg,
  isRequired,
  isDisabled,
  handleChange
}: InputProps) => {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);

  const isPassword = useMemo(() => {
    return type === 'password';
  }, [type]);

  const customType = useMemo(() => {
    if (isPassword && isShowPassword) {
      return 'text';
    }

    return type;
  }, [isPassword, isShowPassword, type]);

  const customIcon = useMemo(() => {
    if (isPassword) {
      return isShowPassword ? 'fa-eye-slash' : 'fa-solid fa-eye';
    }

    return icon;
  }, [isPassword, isShowPassword, icon]);

  return (
    <div className="base-input">
      <div className="base-input__label">
        {label}
        {isRequired && ' *'}
      </div>
      <div
        className={getCssClass({
          '--disabled': !!isDisabled
        }, 'base-input__control')}
      >
        <input
          className={getCssClass({
            '--has-icon': !!icon
          }, 'base-input__control-input')}
          type={customType}
          name={name}
          placeholder={placeHolder}
          value={value}
          onChange={handleChange}
        />
        {!!customIcon && (
          <i
            className={getCssClass({
              [customIcon]: true,
              '--pointer': isPassword
            }, 'fa base-input__control-icon')}
            onClick={isPassword ? () => setShowPassword(!isShowPassword) : () => {}}
          />
        )}
        {!!errorMsg && (
          <p className="base-input__error-message">{errorMsg.replace('{fieldName', name)}</p>
        )}
      </div>
    </div>
  )
};

export default Input;
