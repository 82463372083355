import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Header.scss';
import {
  LOGIN_EMPLOYEE_COOKIE,
  LOGIN_ADMIN_COOKIE,
  LOGIN_COOKIE,
  EmployeePages,
  AdminPages,
  Pages
} from '../../constants';
import { getCookie, deleteCookie, getCssClass, parseJwt } from '../../utils';
import { NavLink, useHistory } from 'react-router-dom';

interface HeaderProps {
  isAdmin?: boolean;
  isEmployee?: boolean;
}

const time = [
  {
    min: 6,
    max: 11,
    text: 'buổi sáng',
    className: 'morning'
  },
  {
    min: 12,
    max: 18,
    text: 'buổi trưa',
    className: 'afternoon'
  },
  {
    min: 17,
    max: 22,
    text: 'buổi chiều',
    className: 'evening'
  },
  {
    min: 23,
    max: 5,
    text: 'buổi tối',
    className: 'night'
  }
]

const Header = ({ isAdmin, isEmployee }: HeaderProps) => {
  const history = useHistory()
  const [hourTime, setHourTime] = useState<number>(new Date().getHours());

  const cookieName = useMemo(() => {
    return isAdmin ? LOGIN_ADMIN_COOKIE : isEmployee ? LOGIN_EMPLOYEE_COOKIE : LOGIN_COOKIE;
  }, [isAdmin, isEmployee])

  const currentPage = useMemo(() => {
    return isAdmin ? AdminPages : isEmployee ? EmployeePages : Pages;
  }, [isAdmin, isEmployee])

  const userName = useMemo(() => {
    return parseJwt(getCookie(cookieName)).userName;
  }, [cookieName])

  const currentTime = useMemo(() => {
    return time.find(item => item.min <= hourTime && hourTime <= item.max) || time[time.length - 1];
  }, [hourTime]);

  const greeting = useMemo(() => {
    return `Chào ${currentTime.text}`
  }, [currentTime.text])

  const handleLogout = useCallback(() => {
    deleteCookie(cookieName)
    history.push(currentPage.LOGIN);
  }, [cookieName, currentPage, history]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setHourTime(new Date().getHours())
    }, 30 * 60 * 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [])

  return (
    <div
      className={getCssClass({
        [currentTime.className]: true
      },'header-component')}
    >
      <div className="header-component__greeting">
        <p>Xin chào {userName}</p>
        <p>{greeting}</p>
      </div>
      <div className="header-component__menu">
        <NavLink
          exact
          className="header-component__menu-item"
          to={currentPage.HOME}
        >Trang chủ</NavLink>
        {isAdmin && (
          <>
            <NavLink
              className="header-component__menu-item"
              to={AdminPages.MANAGE_ACCOUNTS}
            >Trang quản lý tài khoản</NavLink>
            <NavLink
              className="header-component__menu-item"
              to={AdminPages.REGISTER}
            >Trang đăng ký</NavLink>
            <NavLink
              className="header-component__menu-item"
              to={AdminPages.REPORT}
            >Trang báo cáo</NavLink>
          </>
        )}
        {isEmployee && (
          <>
            <NavLink
              className="header-component__menu-item"
              to={EmployeePages.REPORT}
            >Trang báo cáo</NavLink>
          </>
        )}
        <div className="header-component__menu-item --auto" />
        <NavLink
          className="header-component__menu-item"
          to={currentPage.CHANGE_PASSWORD}
        >Đổi mật khẩu</NavLink>
        <p className="header-component__menu-item --log-out" onClick={handleLogout}>Đăng xuất</p>
      </div>
    </div>
  )
}

export default Header;
