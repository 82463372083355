import React from 'react';
import { useHistory } from 'react-router-dom'
import { getCookie } from '../utils'
import { LOGIN_ADMIN_COOKIE, AdminPages } from '../constants'
import LoginAdmin from '../components/LoginAdmin';

const LoginAdminPage = () => {
  const history = useHistory()
  const isLoggedIn = !!getCookie(LOGIN_ADMIN_COOKIE)

  if (isLoggedIn) {
    history.push(AdminPages.HOME);
  }

  return <LoginAdmin />
};

export default LoginAdminPage;
