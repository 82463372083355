import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Button from '../common/Button';
import './ManageAccounts.scss';
import Toast from '../common/Toast';
import { getService, deleteService } from '../../utils/service';
import Header from '../Header';
import Popup from '../common/Popup';
import { getCookie } from '../../utils';
import { LOGIN_ADMIN_COOKIE } from '../../constants';
import Table from '../common/Table';
import Loader from '../common/Loader';

const ManageAccounts = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [employees, setEmployees] = useState<string[]>([]);
  const [userNames, setUserNames] = useState<string[]>([]);
  const [currentData, setCurrentData] = useState<any>(null);
  const [isRemoveModal, setRemoveModal] = useState<boolean>(false);
  const [isLoadingRemove, setLoadingRemove] = useState<boolean>(false);

  const UserNamesOptions = useMemo(() => {
    return [...userNames.map((employee) => ({
      userName: employee,
      type: 'users',
      typeName: 'Người dùng'
    }))]
  }, [userNames])

  const EmployeesOptions = useMemo(() => {
    return [
      ...employees.map((employee) => ({
        userName: employee,
        type: 'employees',
        typeName: 'Nhân viên'
      }))
    ]
  }, [employees])

  const CONFIG_DATA = useMemo(() => {
    return [
      {
        label: 'Tên tài khoản',
        keyMapping: 'userName'
      },
      {
        label: 'Loại',
        keyMapping: 'typeName'
      },
      {
        label: '',
        keyMapping: 'actions'
      }
    ]
  }, []);

  const data = useMemo(() => {
    return [...EmployeesOptions, ...UserNamesOptions]
  }, [EmployeesOptions, UserNamesOptions])

  const renderActons = useCallback((row: any) => {
    return (
      <div className="manage-accounts-component__group-actions">
        <i
          className="fa fa-trash manage-accounts-component__group-actions-item"
          title="Xóa"
          onClick={() => {
            setCurrentData(row);
            setRemoveModal(true);
          }}
        />
      </div>
    )
  }, [])

  const fetchEmployees = useCallback(async () => {
    setLoading(true);

    const { data, error } = await getService('employees/get') as any;

    setLoading(false);

    if (error) {
      return;
    }

    setEmployees(data.data);
  }, [])

  const fetchUserNames = useCallback(async () => {
    setLoading(true);

    const { data, error } = await getService('users/get') as any;

    setLoading(false);

    if (error) {
      return;
    }

    setUserNames(data.data);
  }, [])

  const handleRemove = useCallback(async () => {
    setLoadingRemove(true);

    const { error } = await deleteService(
      `${currentData.type}/delete`,
      {
        userName: currentData.userName,
      },
      {
        'access-token': getCookie(LOGIN_ADMIN_COOKIE)
      }
    ) as any;

    setLoadingRemove(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setRemoveModal(false);
    setCurrentData(null);
    setMsg('Xóa thành công.');
    fetchEmployees();
    fetchUserNames();
  }, [fetchEmployees, fetchUserNames, currentData])

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => {
    fetchUserNames();
  }, [fetchUserNames]);

  return (
    <div className="manage-accounts-component">
      <Header isAdmin />
      <div className="section">
        <h2 className="section__title">Trang quản lý tài khoản</h2>
        <Table configData={CONFIG_DATA} data={data}>
          {renderActons}
        </Table>
        {isLoading && <Loader />}
      </div>
      {isRemoveModal && (
        <Popup
          isShow
          close={() => {
            setRemoveModal(false)
            setCurrentData(null)
          }}
        >
          <div className="remove-popup">
            <p className="remove-popup__title">Xóa dữ liệu?</p>
            <p className="remove-popup__description">Bạn có chắc muốn xóa dữ liệu?</p>
            <div className="remove-popup__actions">
              <Button
                isLoading={isLoadingRemove}
                isDisabled={isLoadingRemove}
                handleClick={() => {
                  setRemoveModal(false)
                  setCurrentData(null)
                }}
              >
                Hủy bỏ
              </Button>
              <Button
                classModifier="--blue"
                isLoading={isLoadingRemove}
                isDisabled={isLoadingRemove}
                handleClick={handleRemove}
              >
                Xóa
              </Button>
            </div>
          </div>
        </Popup>
      )}
      <Toast
        msg={msg}
        icon={hasError ? "fa-warning" : "fa-check-circle"}
        onClosed={() => setMsg('')}
      />
    </div>
  );
};

export default ManageAccounts;
