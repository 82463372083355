import { isEmpty } from 'lodash';
import moment from 'moment';

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (name: string) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getCssClass = (classObject: { [key: string]: boolean}, classStr: string) => {
  const classStrs = !isEmpty(classObject)
    ? Object.keys(classObject).filter(className => classObject[className]).join(' ')
    : '';

  return `${classStr || ''} ${classStrs}`.trim();
}

export const parseJwt = (token: string) => {
  if (!token) {
    return {};
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const checkDisabledStatusItem = (index: number, selectedIndex: number) => {
  return index < selectedIndex;
}

export const getPreviousMonth = (date = new Date(), duration: number) => {
  return moment(date).subtract(duration, 'months').endOf('month');
}
