import React from 'react'
import { getCssClass } from '../../../utils';
import './button.scss'
import Loader from '../Loader';

export default function Button (props: {
  children: any;
  classModifier?: string;
  handleClick?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: any;
}) {
  const {
    children, classModifier = '', handleClick, isLoading = false, isDisabled = false, type = 'button'
  } = props

  return (
    <button
      type={type}
      className={getCssClass({
        [classModifier]: true,
        '--disabled': isDisabled
      }, 'btn')}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {isLoading ? <Loader className="--position-static" /> : children}
    </button>
  )
}
