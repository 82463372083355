import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Input from '../common/Input';
import Button from '../common/Button';
import './Login.scss';
import { LOGIN_ADMIN_COOKIE, AdminPages } from '../../constants';
import { setCookie } from '../../utils';
import Toast from '../common/Toast';
import { postService } from '../../utils/service';

const Login = () => {
  const history = useHistory()

  const [values, setValues] = useState<{
    userName: string;
    password: string;
  }>({
    userName: '',
    password: ''
  })
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;

    setValues((prevValue) => ({
      ...prevValue,
      [name]: value
    }))
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const { data, error } = await postService('admin/login', values) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setCookie(LOGIN_ADMIN_COOKIE, data.data, 2)
    history.push(AdminPages.HOME);
    setHasError(false);
    setMsg('Đăng nhập thành công.')
  }, [values, history]);

  return <div className="section login-component">
    <h2 className="section__title">Trang đăng nhập</h2>
    <form className="login-component__form" onSubmit={handleSubmit}>
      <Input
        type="text"
        name="userName"
        label="Tên đăng nhập"
        value={values.userName}
        handleChange={handleChange}
      />
      <Input
        type="password"
        name="password"
        label="Mật khẩu"
        value={values.password}
        handleChange={handleChange}
      />
      <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>Đăng nhập</Button>
    </form>
    <Toast
      msg={msg}
      icon={hasError ? "fa-warning" : "fa-check-circle"}
      onClosed={() => setMsg('')}
    />
  </div>;
};

export default Login;
