import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Input from '../common/Input';
import Button from '../common/Button';
import './ChangePasswordAdmin.scss';
import Toast from '../common/Toast';
import { getService, postService } from '../../utils/service';
import Header from '../Header';
import Select from '../common/Select';

const DEFAULT_VALUE = {
  type: '',
  userName: '',
  password: '',
  rePassword: '',
};

const ChangePassword = () => {
  const [values, setValues] = useState<{
    type: string;
    userName: string;
    password: string;
    rePassword: string;
  }>(DEFAULT_VALUE)
  const [isLoading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [employees, setEmployees] = useState<string[]>([]);
  const [userNames, setUserNames] = useState<string[]>([]);

  const TypeOptions = useMemo(() => {
    return [
      {
        text: 'Nhân viên',
        value: 'employee',
      },
      {
        text: 'Người dùng',
        value: 'user',
      },
    ]
  }, [])

  const UserNamesOptions = useMemo(() => {
    return [...userNames.map((employee) => ({
      text: employee,
      value: employee,
    }))]
  }, [userNames])

  const EmployeesOptions = useMemo(() => {
    return [
      ...employees.map((employee) => ({
        text: employee,
        value: employee,
      }))
    ]
  }, [employees])

  const urlChangePassword = useMemo(() => {
    return values.type === 'employee' ? 'employee-change-password' : 'change-password';
  }, [values.type]);

  const fetchEmployees = useCallback(async () => {
    const { data, error } = await getService('employees/get') as any;

    if (error) {
      return;
    }

    setEmployees(data.data);
  }, [])

  const fetchUserNames = useCallback(async () => {
    const { data, error } = await getService('users/get') as any;

    if (error) {
      return;
    }

    setUserNames(data.data);
  }, [])

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;

    setValues((prevValue) => ({
      ...prevValue,
      [name]: value
    }))
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const { error } = await postService(urlChangePassword, {
      userName: values.userName,
      password: values.password,
      rePassword: values.rePassword,
    }) as any;

    setLoading(false);

    if (error) {
      setHasError(true);
      setMsg(error.data.message);
      return;
    }

    setHasError(false);
    setMsg('Đổi mật khẩu thành công.');
  }, [urlChangePassword, values]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => {
    fetchUserNames();
  }, [fetchUserNames]);

  return (
    <div className="change-password-component">
      <Header isAdmin />
      <form className="change-password-component__form" onSubmit={handleSubmit}>
        <Select
          name="type"
          label="Chọn chức danh"
          placeHolder="Lựa chọn"
          defaultOption={TypeOptions.find(option => option.value === values.type)}
          options={TypeOptions}
          handleChanged={(selectedValue) => {
            setValues((prevValues) => ({
              ...prevValues,
              type: selectedValue.value.value
            }))
          }}
        />
        {!!values.type && (
          <>
            {values.type === 'user' && (
              <Select
                name="userName"
                label="Tên người dùng"
                placeHolder="Lựa chọn"
                defaultOption={UserNamesOptions.find(option => option.value === values.userName)}
                options={UserNamesOptions}
                handleChanged={(selectedValue) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    userName: selectedValue.value.value
                  }))
                }}
              />
            )}
            {values.type === 'employee' && (
              <Select
                name="employee"
                label="Tên nhân viên"
                placeHolder="Lựa chọn"
                defaultOption={EmployeesOptions.find(option => option.value === values.userName)}
                options={EmployeesOptions}
                handleChanged={(selectedValue) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    userName: selectedValue.value.value
                  }))
                }}
              />
            )}
          </>
        )}
        {!!values.userName && (
          <>
            <Input
              type="password"
              name="password"
              label="Mật khẩu"
              value={values.password}
              handleChange={handleChange}
            />
            <Input
              type="password"
              name="rePassword"
              label="Nhập lại mật khẩu"
              value={values.rePassword}
              handleChange={handleChange}
            />
            <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>Đổi mật khẩu</Button>
          </>
        )}
      </form>
      <Toast
        msg={msg}
        icon={hasError ? "fa-warning" : "fa-check-circle"}
        onClosed={() => setMsg('')}
      />
    </div>
  );
};

export default ChangePassword;
