import React from 'react'

import './loader.scss'
import { getCssClass } from '../../../utils';

interface LoaderProps {
  className?: string;
}

export default function Loader ({ className = '' }: LoaderProps) {
  return (
    <div className={getCssClass({
      [className]: true
    }, 'component-loader')}>
      <div className="component-loader__inner"></div>
    </div>
  )
}
